<template>
  <div v-if="currentState">
    <transition name="slide" appear>
      <div v-if="warningArea" class="warningArea">
        {{ warningMessage }}
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "autotimer",

  data() {
    return {
      events: ["click", "mousedown", "scroll", "keypress", "load"],
      currentState: null,
      warningTimer: null,
      logoutTimer: null,
      warningMessage: "",
      showTimeRemaining: null,
      warningArea: false,
      warningTime: 15 * 60 * 1000,
      timeRemaining: 30,
      timeRemainingDefault: 30,
    };
  },
  mounted() {
    this.events.forEach(function (event) {
      window.addEventListener(event, this.resetTimer);
    }, this);
    this.setTimers();
  },
  methods: {
    setTimers() {
      this.warningArea = false;
      this.warningTimer = setTimeout(this.showWarningMessage, this.warningTime);
    },
    showWarningMessage() {
      this.currentState = this.$store.state.userSession.authorised;
      this.timeRemaining = this.timeRemainingDefault;
      this.warningMessage =
        "You have not been using for " +
        this.warningTime / 1000 +
        " seconds. You will be logged out in " +
        this.timeRemaining +
        " seconds.";
      this.warningArea = true;
      clearInterval(this.showTimeRemaining);
      this.showTimeRemaining = setInterval(this.setTimeRemaining, 1000);
    },
    resetTimer() {
      this.$store.commit("lastAction");
      clearInterval(this.showTimeRemaining);
      clearTimeout(this.warningTimer);
      this.setTimers();
    },
    setTimeRemaining() {
      clearInterval(this.showTimeRemaining);
      this.warningMessage =
        "You have not been using for " +
        this.warningTime / 1000 / 60 +
        " minutes. You will be logged out in " +
        this.timeRemaining +
        " seconds.";
      this.timeRemaining--;
      if (this.timeRemaining == -1) {
        this.autoLogout();
      } else {
        this.showTimeRemaining = setInterval(this.setTimeRemaining, 1000);
      }
    },
    autoLogout() {
      this.$store.dispatch("sessionCleanup");
      this.warningArea = false;
      this.$store.commit("logout");
      this.$router.push({ path: "/" });
    },
  },
};
</script>

<style>
.warningArea {
  background-color: #fc4140;
  margin-bottom: 20px;
}
.slide-enter-from {
  opacity: 0;
  transform: translateY(-3rem);
}
.slide-enter-active {
  transition: all 0.5s ease-in;
}
.slide-enter-to {
  opacity: 1;
  transform: translateY(0);
}
</style>
