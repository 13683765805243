<template>
  <v-app>
    <v-main>
      <AutoLogout />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import AutoLogout from "./components/AutoLogout.vue";

export default {
  name: "App",
  components: { AutoLogout },
  data: () => ({
    //
  }),
};
</script>
<style>
body {
  font-family: Arial, Helvetica, sans-serif;
}
.v-field__input {
  font-family: Arial, Helvetica, sans-serif;
}
</style>
