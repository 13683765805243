import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import "./components/vuwbo_main.css";
import VueCookies from "vue-cookies";
import Store from "./store/index";
import { VDataTable } from "vuetify/labs/VDataTable";

loadFonts();

createApp(App)
  .use(router)
  .use(VDataTable)
  .use(Store)
  .use(VueCookies)
  .use(vuetify)
  .mount("#app");
