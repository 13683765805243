import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import VueCookies from "vue-cookies";
import Axios from "axios";

export default createStore({
  state: {
    auth: null,
    authDesc: null,
    userSession: {
      authorised: false,
      organisation: "",
      activeOrganisation: "",
      username: "",
      displayname: "",
    },
    userDates: {
      from: null,
      to: null,
    },
    orgData: {},
    organisationSettings: {},
    currentReview: null,
    currentReviewOrigin: null,
    currentCurReview: null,
    currentCurReviewOrigin: null,
    lastActionTime: null,
    showNavbar: false,
    pageView: {},
    reset: {},
  },
  mutations: {
    login(state, loginData) {
      state.userSession = {
        authorised: true,
        organisation: loginData.organisation,
        activeOrganisation: loginData.organisation,
        username: loginData.username,
        userEmail: loginData.userEmail,
        displayname: loginData.displayname,
      };
      state.lastActionTime = new Date();
    },
    logout(state) {
      state.userSession = {
        authorised: false,
        organisation: null,
        activeOrganisation: null,
        username: null,
      };
      state.organisationSettings = {};
      state.currentReview = null;
      state.currentReviewOrigin = null;
      state.currentCurReview = null;
      state.currentCurReviewOrigin = null;
      VueCookies.remove("session_data");
    },
    organisationSettings(state, organisationSettings) {
      state.organisationSettings = organisationSettings;
    },
    setLogo(state, logoPath) {
      state.organisationSettings.orgLogo = logoPath;
    },
    organisationSliderOrder(state, sliderPriorityList) {
      state.orgData.sliderOrder = sliderPriorityList;
    },
    initialise(state, orgData) {
      state.orgData = orgData;
    },
    setAuth(state, value) {
      state.auth = value.level;
      state.authDesc = value.levelDesc;
    },
    setNavBar(state, value) {
      state.showNavbar = value;
    },
    lastAction(state) {
      state.lastActionTime = new Date();
    },
    setDates(state, dateValues) {
      state.userDates.from = dateValues.from;
      state.userDates.to = dateValues.to;
    },
    setPageReset(state) {
      state.pageView = {};
    },
    setPageSearch(state, pageSearch) {
      state.pageView.pageSearch = pageSearch;
    },
    setPageSize(state, pageSize) {
      state.pageView.itemsPerPage = pageSize;
    },
    setPageFilter(state, pageFilter) {
      state.pageView.pageFilter = pageFilter;
    },
    updateDepts(state, depts) {
      state.orgData.depts = depts;
    },
    setOrganisation(state, organisation) {
      state.userSession.activeOrganisation = organisation;
    },
    vuwbo_review(state, reviewDetails) {
      state.currentReview = reviewDetails.token;
      state.currentReviewOrigin = reviewDetails.originalUrl;
    },
    curriculum_review(state, reviewDetails) {
      state.currentCurReview = reviewDetails.token;
      state.currentCurReviewOrigin = reviewDetails.originalUrl;
    },
    reset(state, resetdata) {
      state.reset = resetdata;
    },
  },
  actions: {
    sessionCleanup() {
      const url = process.env.VUE_APP_VUWBO_API + "/rest.php";
      const headers = {
        "Content-Type": "application/json",
        withCredentials: true,
      };
      var postdata = {
        module: "authorise",
        action: "sessionCleanup",
        details: {},
      };
      Axios.post(url, postdata, headers).then((res) => {
        if (res.data) {
          if (res.data == "denied") {
            this.$store.commit("logout");
            this.$router.push({ path: "/" });
          } else {
            console.log(res.data);
          }
        }
      });
    },
    getUser(state) {
      return state.userSession;
    },
    checkError(state, errorCode) {
      let errormsg = "Error";
      switch (errorCode) {
        case "err_01":
          errormsg = "Organisation name already taken";
          break;
        case "err_02":
          errormsg = "Invalid organisation name";
          break;
      }
      return errormsg;
    },
  },
  getters: {},
  modules: {},
  plugins: [createPersistedState()],
});
