import { createRouter, createWebHistory } from "vue-router";
import store from "../store/index";

const routes = [
  {
    path: "/error",
    name: "error",
    component: () => import("../components/VuwboError"),
  },
  {
    path: "/vuwbolink",
    name: "vuwbolink",
    component: () => import("../components/VuwboLink"),
  },
  {
    path: "/vuwbolink_closed",
    name: "vuwbolink_closed",
    component: () => import("../components/VuwboLinkClosed"),
  },
  {
    path: "/VuwboReset",
    name: "VuwboReset",
    component: () => import("../components/VuwboReset"),
  },
  {
    path: "/VuwboSetUp",
    name: "VuwboSetUp",
    component: () => import("../components/VuwboSetUp"),
  },
  {
    path: "/",
    name: "Login",
    component: () => import("../components/Login"),
  },
  {
    path: "/index.html",
    name: "Logout",
    component: () => import("../components/Login"),
  },
  {
    path: "/vuwbo_online",
    name: "vuwbo_online",
    component: () => import("../components/VuwboOnline/VuwboOnline.vue"),
    meta: { requiresLogin: true },
  },
  {
    path: "/quick_charts",
    name: "quick_charts",
    component: () => import("../components/QuickCharts"),
    meta: { requiresLogin: true },
  },
  {
    path: "/support",
    name: "support",
    component: () => import("../components/Support"),
    meta: { requiresLogin: true },
  },
  {
    path: "/reports",
    name: "reports",
    component: () => import("../components/Reports"),
    meta: { requiresLogin: true },
  },
  // USER ADMIN
  {
    path: "/admin",
    name: "admin",
    component: () => import("../components/Admin/Admin"),
    meta: { requiresLogin: true },
  },
  {
    path: "/admin_groups",
    name: "admin_groups",
    component: () => import("../components/Admin/AdminGroups"),
    meta: { requiresLogin: true },
  },
  {
    path: "/admin_lists",
    name: "admin_lists",
    component: () => import("../components/Admin/AdminLists"),
    meta: { requiresLogin: true },
  },
  {
    path: "/admin_log",
    name: "admin_log",
    component: () => import("../components/Admin/AdminLog"),
    meta: { requiresLogin: true },
  },
  {
    path: "/admin_questions",
    name: "admin_questions",
    component: () => import("../components/Admin/AdminQuestions"),
    meta: { requiresLogin: true },
  },
  {
    path: "/admin_settings",
    name: "admin_settings",
    component: () => import("../components/Admin/AdminSettings"),
    meta: { requiresLogin: true },
  },
  {
    path: "/admin_sliders",
    name: "admin_sliders",
    component: () => import("../components/Admin/AdminSliders"),
    meta: { requiresLogin: true },
  },
  {
    path: "/admin_users",
    name: "admin_users",
    component: () => import("../components/Admin/AdminUsers"),
    meta: { requiresLogin: true },
  },
  //Sliders
  {
    path: "/new_slider",
    name: "admin_sliders_new",
    component: () => import("../components/Admin/Sliders/AdminSlidersNew.vue"),
    meta: { requiresLogin: true },
  },
  {
    path: "/edit_sliders",
    name: "admin_sliders_edit",
    component: () => import("../components/Admin/Sliders/AdminSlidersEdit.vue"),
    meta: { requiresLogin: true },
  },
  {
    path: "/order_sliders",
    name: "admin_sliders_order",
    component: () =>
      import("../components/Admin/Sliders/AdminSlidersOrder.vue"),
    meta: { requiresLogin: true },
  },
  {
    path: "/descriptors_sliders",
    name: "admin_sliders_descriptors",
    component: () =>
      import("../components/Admin/Sliders/AdminSlidersLevelDescriptors.vue"),
    meta: { requiresLogin: true },
  },
  {
    path: "/evaluation_sliders",
    name: "admin_sliders_evaluation",
    component: () =>
      import("../components/Admin/Sliders/AdminSlidersEvaluationScale.vue"),
    meta: { requiresLogin: true },
  },
  // Questions
  {
    path: "/manage_questions",
    name: "manage_questions",
    component: () => import("../components/Admin/AdminManageQuestions"),
    meta: { requiresLogin: true },
  },
  {
    path: "/question_sets",
    name: "question_sets",
    component: () => import("../components/Admin/AdminQuestionSets"),
    meta: { requiresLogin: true },
  },
  // VUWBO ADMIN
  {
    path: "/vuwbo_admin",
    name: "vuwbo_admin",
    component: () => import("../components/VuwboAdmin/VuwboAdmin"),
    meta: { requiresLogin: true },
  },
  {
    path: "/vuwbo_admin_organisations",
    name: "vuwbo_admin_organisations",
    component: () => import("../components/VuwboAdmin/VuwboAdminOrganisations"),
    meta: { requiresLogin: true },
  },
  {
    path: "/vuwbo_admin_log",
    name: "vuwbo_admin_log",
    component: () => import("../components/Admin/AdminLog"),
    meta: { requiresLogin: true },
  },
  {
    path: "/vuwbo_admin_dev",
    name: "vuwbo_admin_dev",
    component: () => import("../components/VuwboAdmin/VuwboDev"),
    meta: { requiresLogin: true },
  },
  {
    path: "/vuwbo_admin_users",
    name: "vuwbo_admin_users",
    component: () => import("../components/VuwboAdmin/VuwboAdminUsers"),
    meta: { requiresLogin: true },
  },
  {
    path: "/vuwbo_admin_user_support",
    name: "vuwbo_admin_user_support",
    component: () => import("../components/VuwboAdmin/VuwboAdminUserSupport"),
    meta: { requiresLogin: true },
  },
  // REVIEWS
  {
    path: "/reviews_menu",
    name: "reviews_menu",
    component: () => import("../components/Reviews/ReviewsMenu"),
    meta: { requiresLogin: true },
  },
  {
    path: "/reviews",
    name: "reviews",
    component: () => import("../components/Reviews/Reviews"),
    meta: { requiresLogin: true },
  },
  {
    path: "/reviews_new",
    name: "reviewsNew",
    component: () => import("../components/Reviews/ReviewsNew"),
    meta: { requiresLogin: true },
  },
  {
    path: "/walks",
    name: "walks",
    component: () => import("../components/Reviews/ReviewsWalks"),
    meta: { requiresLogin: true },
  },
  {
    path: "/reviews_new_walk",
    name: "reviews_new_walk",
    component: () => import("../components/Reviews/ReviewsNewWalk"),
    meta: { requiresLogin: true },
  },
  {
    path: "/tas",
    name: "tas",
    component: () => import("../components/Reviews/ReviewsTAs"),
    meta: { requiresLogin: true },
  },
  {
    path: "/reviews_new_sa",
    name: "reviews_new_sa",
    component: () => import("../components/Reviews/ReviewsTAsNew"),
    meta: { requiresLogin: true },
  },
  {
    path: "/curriculum",
    name: "curriculum",
    component: () => import("../components/Reviews/ReviewsCurriculum"),
    meta: { requiresLogin: true },
  },
  {
    path: "/curriculum_review",
    name: "curriculum_review",
    component: () => import("../components/Reviews/ReviewsCurriculumReview"),
    meta: { requiresLogin: true },
  },
  {
    path: "/reviews_new_department",
    name: "reviews_new_department",
    component: () => import("../components/Reviews/ReviewsCurriculumNew"),
    meta: { requiresLogin: true },
  },
  {
    path: "/my_support",
    name: "my_support",
    component: () => import("../components/MySupport"),
    meta: { requiresLogin: true },
  },
  {
    path: "/account",
    name: "account",
    component: () => import("../components/Account"),
    meta: { requiresLogin: true },
  },
  {
    path: "/vuwbo_review",
    name: "VuwboReview",
    component: () => import("../components/VuwboReview"),
    meta: { requiresLogin: true },
  },
  {
    path: "/vuwbo_review_questions",
    name: "VuwboReviewQuestions",
    component: () => import("../components/VuwboReviewQuestions"),
    meta: { requiresLogin: true },
  },
  {
    path: "/vuwbo_online",
    name: "recent",
    component: () => import("../components/VuwboOnline/VuwboOnline.vue"),
    meta: { requiresLogin: true },
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("../components/NotFound"),
  },
];

const router = createRouter({
  base: process.env.BASE_URL,
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach((to, from, next) => {

// else {
//   const user = store.state.userSession;
//   if (to.meta.requiresLogin && !user.authorised) {
//     next("/");
//   } else {
//     next();
//   }
//   if (to.name == "Login" && user) {
//     next(from.path);
//   }

//   if ((to.name == "vuwbo_review" || to.name == "Login") && user) {
//     store.state.showNavbar = false;
//   } else {
//     store.state.showNavbar = true;
//   }
// }
// });
router.beforeEach(async (to) => {
  if (
    to.name != "vuwbolink" &&
    to.name != "VuwboReset" &&
    to.name != "VuwboSetUp"
  ) {
    const user = store.state.userSession;
    var lastTime = new Date(store.state.lastActionTime);
    var currentTime = new Date();
    var dateDiff = currentTime - lastTime;
    const sessionTime = 20 * 60 * 1000;
    if (user.authorised && dateDiff > sessionTime) {
      store.commit("logout");
      return { name: "Login" };
    } else {
      if (!user.authorised && to.name !== "Login") {
        return { name: "Login" };
      }
    }
  }
});

export default router;
